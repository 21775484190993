<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="520 450 300 500">
    <path
      :class="parte ==='mano' || parte ==='otro' ? 'part_marked':''"
      d="M680,794.53A43.07,43.07,0,0,1,714.83,790l.06.08a21.24,21.24,0,0,0,3.37,3.89c7.24,6.53,9.12,15,11.76,23.52,1.1,3.55,2.69,6.95,4.05,10.42l-3.11,5-5,.41c.43,4.77.83,10,1.39,15.13.41,3.73,1.57,7.46,1.36,11.14-.12,2.08-1.88,5.11-3.67,5.84-3.65,1.5-5.63-1.21-7.11-4.71,0,2.65-.05,5.31,0,7.95.06,2.94-1.11,4.62-4.2,5-3.62.47-5.2-1.41-5.77-4.55a170.25,170.25,0,0,0-4-18.48V868c-7.21,2.58-10.38-.48-11-10.18-.45-6.69-1-13.37-2-20.06,0,4,.15,7.93,0,11.89-.15,3.26.58,7.54-4.3,7.91s-6.09-3.84-6.46-7.26c-.59-5.43-.13-11-.14-16.47,0-4.7.63-9.51-.2-14.06-1.53-8.47-1.86-16.81.11-25.17Z"
    />
    <path
      :class="parte ==='biceps' || parte ==='otro' || parte ==='todo_brazo'? 'part_marked':''"
      d="M642.41,648c-.31-2.38-.62-4.71-.93-7.09-.68-5.17-1.63-10.31-2.53-15.45a29.86,29.86,0,0,0-1-3.56,5.86,5.86,0,0,1-.17-.75l-.18-1s-12.2-56.48-1.67-123.26l0-.14c8.89,3.92,18,7.23,26.95,11.15,9.73,4.3,12.85,13.23,13.91,22.69,1.27,11.29,1.57,22.68,2.09,34a21.18,21.18,0,0,1-1,7.67c-2.57,7.59,1.16,14.57,1.61,21.84.54,8.94,1.24,18.07,0,26.87a57,57,0,0,0-.48,10.69l-.07.06S662.21,646.43,642.41,648Z"
    />
    <path
      :class="parte ==='otro'|| parte ==='todo_brazo' ? 'part_marked':''"
      d="M643.57,658.45c-.3-3.53-.71-7.06-1.16-10.58l.31,0c19.8-1.58,36.18-16.12,36.18-16.12l.2-.16a56.65,56.65,0,0,0,4.25,18.86l.08.2s-24-10.15-39.64,7.57Z"
    />
    <path
      :class="parte ==='antebrazo' || parte ==='otro' || parte ==='todo_brazo'? 'part_marked':''"
      d="M678.87,778.18c-2.71-7.32-5-14.8-7.78-22.08s-5.9-14.78-9.29-22c-7.79-16.53-14.44-33.47-17.56-51.5-1.15-6.68,0-13.72-.39-20.56-.07-1.25-.17-2.34-.28-3.59v-.16c15.61-17.73,39.86-7.81,39.86-7.81l0,.09c3.36,8.31,7.31,16.36,9.77,24.93,1.91,6.69,1.85,13.93,2.74,20.92,2,15.58,3.62,31.22,6.16,46.71,1.6,9.75,3.54,19.05,6,28.64l.1.41s-18.77-6.88-29.23,5.83Z"
    />
    <path
      :class="parte ==='muneca' || parte ==='otro' || parte ==='todo_brazo'? 'part_marked':''"
      d="M680,794.54a27.55,27.55,0,0,0-1.06-16.31l0-.11c10.47-12.71,29.35-6,29.35-6l0,.17q1,3.75,2,7.47a33.88,33.88,0,0,0,4.54,10.05l.09.13A43.31,43.31,0,0,0,680,794.56Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BrazoIzquierdo",
  props: ["parte"],
};
</script>
<style lang="sass">
path
  fill: var(--v-gris1-base) !important
  &.part_marked
    fill: var(--v-gris2-base) !important
    stroke: #272727
    stroke-width: 0.5
    stroke-miterlimit: 10
.selected_inner
  path
    fill: var(--v-gris1-base) !important
    &.part_marked
      fill: var(--v-primary-base) !important
      fill: #E4BB6D
      stroke: #000000
      stroke-width: 0.5
      stroke-miterlimit: 10

#body_selector .v-card:hover
  path.part_marked
    fill: #E4BB6D !important
</style>
